import React, { memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Progress } from 'antd';

import { uid } from 'react-uid';

const Percent = ({ type, data }) => {
    const keys = {
        taixiu: [
            { key: 'phanTramTai', color: 'red', label: 'Tài' },
            { key: 'phanTramxiu', color: 'cyan', label: 'Xỉu' },
        ],
        chanle: [
            { key: 'phanTramChan', color: 'red', label: 'Chẵn' },
            { key: 'phanTramle', color: 'cyan', label: 'Lẻ' },
        ],
    };
    return (
        <div className="flex items-center justify-center gap-10 mt-10">
            {keys[type].map(({ key, color, label }) => (
                <div
                    key={key}
                    className="flex items-center gap-5"
                >
                    <Progress
                        type="circle"
                        percent={data[type][key]}
                        strokeColor={color}
                        size="small"
                    />
                    <p>{label}</p>
                </div>
            ))}
        </div>
    );
};

const Result = ({ data, type }) => {
    const keys = {
        taixiu: [
            { key: 'tai', color: 'bg-red-500', label: 'T' },
            { key: 'xiu', color: 'bg-cyan-500', label: 'X' },
        ],
        chanle: [
            { key: 'chan', color: 'bg-red-500', label: 'C' },
            { key: 'le', color: 'bg-cyan-500', label: 'L' },
        ],
    };
    return (
        <div className="flex justify-between mt-4">
            {data[type].cau.map((item, index) => {
                const [[result, amount]] = Object.entries(item);
                const render = [];
                for (let i = 0; i < amount; i++) {
                    render.push(result);
                }
                return (
                    <div
                        className="flex flex-col gap-4"
                        key={uid({ item, index })}
                    >
                        {render.map((key) => {
                            const { label, color } = keys[type].find((item) => item.key === key);
                            return (
                                <div
                                    key={uid({ item, index })}
                                    className={twMerge(
                                        'text-white flex items-center text-2xl w-7 h-7 justify-center rounded-full',
                                        color
                                    )}
                                >
                                    {label}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

const initCau = {
    taixiu: { cau: [], phanTramTai: 0, phanTramxiu: 0 },
    chanle: { cau: [], phanTramChan: 0, phanTramle: 0 },
};

const initData = { tong: initCau, 1: initCau, 2: initCau, 3: initCau, 4: initCau, 5: initCau };

const Cau = memo(({ data = initData }) => {
    const [type, setType] = useState('taixiu');
    return (
        <div className="pb-52 mx-4 text-4xl w-2/3 m-auto">
            <div className="flex justify-around font-thin">
                <p
                    onClick={() => setType('taixiu')}
                    className={twMerge('cursor-pointer', type === 'taixiu' && 'font-bold')}
                >
                    Tài xỉu
                </p>
                <p
                    onClick={() => setType('chanle')}
                    className={twMerge('cursor-pointer', type === 'chanle' && 'font-bold')}
                >
                    Chẵn lẻ
                </p>
            </div>
            <Percent {...{ data: data.tong, type }} />
            <Result {...{ data: data.tong, type }} />
        </div>
    );
});

export default Cau;

export { default as Header } from './Header';
export { default as Bet } from './Bet';
export { default as BetTxcl } from './BetTxcl';
export { default as BetContent } from './BetContent';
export { default as Result } from './Result';
export { default as HistoryBet } from './HistoryBet';
export { default as Cau } from './Cau';

export const getHistoryPhien = (sendMsg, theloai) => sendMsg({ xoso: { getphien: true, theloai } });

export const getHistoryBet = (sendMsg, theloai, trang) => sendMsg({ xoso: { history: { theloai, limit: 5, trang } } });

export const getRateBet = (sendMsg) => sendMsg({ xoso: { getTiLe: true } });

export const getNextPhien = (sendMsg, theloai) => sendMsg({ xoso: { nextPhien: { theloai } } });

export const getCau = (sendMsg, theloai) => sendMsg({ xoso: { getCau: { theloai } } });

import React, { useState, useEffect } from 'react';
import './Recharge.scss';
import {
    iconACB,
    iconTechcombank,
    iconVietinBank,
    iconBIDV,
    iconVietcomBank,
    iconMomo,
    iconTpBank,
    iconMBBank,
    iconSacomBank,
} from '../../img/importImg';
import rechargeAPI from '../../API/rechargeAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { baseUrl } from '../../dummy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import withdrawAPI from '../../API/withdrawAPI';
import { useSearchParams } from 'react-router-dom';

const VND = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

const bankIcon = {
    ACB: iconACB,
    TECHCOMBANK: iconTechcombank,
    VIETINBANK: iconVietinBank,
    BIDV: iconBIDV,
    VIETCOMBANK: iconVietcomBank,
    MOMO: iconMomo,
    TPBANK: iconTpBank,
    MBBANK: iconMBBank,
    SACOMBANK: iconSacomBank,
};

function RechargeBank(args) {
    const [selectedValue, setSelectedValue] = useState();
    const [customTab, setCustomTab] = useState(0);
    const [listBank, getListBank] = useState();
    const [ttchitietCK, setTTChiTietCK] = useState();
    const [modalOne, setModalOne] = useState(false);
    const [money, setMoney] = useState();
    const [noidung, setNoiDung] = useState(sessionStorage.getItem('username'));
    const [copied, setCopied] = useState(false);
    const [copiedNoiDung, setCopiedNoiDung] = useState(false);
    const [contentCopy, setContentCopy] = useState();
    const [settings, setSettings] = useState();

    const [searchParams] = useSearchParams();
    const idPromotion = searchParams.get('id_promotion');

    const toggleOne = () => setModalOne(!modalOne);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        try {
            const fetchListBank = async () => {
                const result = await rechargeAPI.getListBank();
                const dataSettings = await withdrawAPI.getSetting();

                // console.log(dataSettings);
                if (result.status === true) {
                    const data = result.data;
                    const dataNew = data.map((item) => {
                        const icon = bankIcon[item.name_bank.toUpperCase()];
                        return icon ? { ...item, icon } : item;
                    });

                    getListBank(dataNew);
                }

                if (dataSettings.status === true) {
                    setSettings(dataSettings.data[0]);
                }
            };
            fetchListBank();
        } catch (error) {
            return toast.error('Có lỗi xảy ra!', { theme: 'light' });
        }
    }, []);

    const handleClickTHNT = () => {
        const detailBankArr = [];

        if (!selectedValue) {
            return toast.error('Vui lòng chọn ngân hàng bạn muốn nạp!', {
                theme: 'light',
            });
        }

        if (listBank.length > 0) {
            listBank.forEach((item) => {
                if (item.name_bank.toUpperCase() === selectedValue) {
                    detailBankArr.push(item);
                }
            });
        }

        if (detailBankArr.length > 0) {
            setTTChiTietCK(detailBankArr[0]);
            if (detailBankArr[0].stk_bank === contentCopy) {
                setCopied(true);
            } else setCopied(false);
        }

        setModalOne(true);
    };

    const handleRecharge = async (e) => {
        try {
            e.preventDefault();
            if (!money || !noidung) {
                return toast.error('Không được để trống!', { theme: 'light' });
            }

            if (settings.money_min_receive) {
                if (money < settings.money_min_receive) {
                    return toast.error(`Số tiền nạp tối thiểu là ${VND.format(settings.money_min_receive)}!`, {
                        theme: 'light',
                    });
                }
            }

            const data = {
                money: money,
                noidung: noidung,
                namebank: ttchitietCK.name_bank,
                chutk: ttchitietCK.name_u_bank,
                stk: ttchitietCK.stk_bank,
                id_km: idPromotion,
            };

            const result = await rechargeAPI.postRecharge(data);
            // console.log(result);

            setModalOne(false);

            if (result.status === true) {
                toast.success('Tạo lệnh nạp tiền thành công, vui lòng chờ duyệt', {
                    theme: 'light',
                });
            } else {
                return toast.error(result.msg, { theme: 'light' });
            }
        } catch (error) {
            return toast.error('Có lỗi xảy ra!', { theme: 'light' });
        }
    };

    return (
        <>
            <div className="el-tabs__content">
                <div className="el-tab-pane">
                    {/* Nạp tiền qua thẻ ngân hàng */}
                    {customTab === 0 && (
                        <form className="el-form">
                            <div className="el-form-item">
                                <label
                                    htmlFor="config_index"
                                    className="el-form-item__label"
                                >
                                    Ngân hàng
                                </label>
                                <div className="el-form-item__content">
                                    <div
                                        role="radiogroup"
                                        className="el-radio-group bankradio"
                                    >
                                        {listBank &&
                                            listBank.map((item, i) => {
                                                return (
                                                    <label
                                                        className="el-radio"
                                                        key={i}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="radio"
                                                            value={item.name_bank.toUpperCase()}
                                                            checked={
                                                                String(selectedValue) ===
                                                                String(item.name_bank.toUpperCase())
                                                            }
                                                            onChange={handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                        <img
                                                            src={item.icon}
                                                            alt=""
                                                        />
                                                        {item.name_bank.toUpperCase()}
                                                    </label>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div className="el-form-item rechargenotice">
                                <div className="el-form-item__content">
                                    {settings && (
                                        <p className="prompt1">
                                            Mức nạp tiền tối thiểu {VND.format(settings.money_min_receive)} Tối đa{' '}
                                            {VND.format(settings.money_max_receive)}
                                        </p>
                                    )}

                                    {/* <p className="prompt1">
                    Lời khuyên: Chúng tôi khuyên bạn nên chuyển số tiền không
                    phải là số nguyên, ví dụ 1023.18
                  </p> */}

                                    <p className="prompt2">
                                        Lời nhắc nhở: Chú ý nhập đúng nội dung khi chuyển khoản, phân biệt kí tự viết
                                        hoa hoặc thường, nhập sai không thể lên điểm.
                                    </p>
                                </div>
                            </div>
                            <div className="el-form-item">
                                <div className="el-form-item__content">
                                    <button
                                        type="button"
                                        className="el-button el-button--primary"
                                        onClick={handleClickTHNT}
                                    >
                                        <span>Tiến hành nạp tiền</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}

                    {ttchitietCK && (
                        <Modal
                            isOpen={modalOne}
                            toggle={toggleOne}
                            {...args}
                            style={{ maxWidth: '750px' }}
                            centered
                        >
                            <ModalHeader
                                toggle={toggleOne}
                                style={{ fontSize: '16px' }}
                            >
                                Thông tin đơn
                            </ModalHeader>
                            <ModalBody style={{ fontSize: '16px' }}>
                                <span>Vui lòng chuyển vào tài khoản ngân hàng sau:</span>
                                <div className="d-flex justify-content-center align-items-center py-4">
                                    <div
                                        className="d-flex flex-column"
                                        style={{ width: '480px' }}
                                    >
                                        <div className="d-flex flex-column border pb-1">
                                            <span className="d-flex align-items-center border-bottom py-2 px-4">
                                                <img
                                                    src={ttchitietCK.icon}
                                                    alt=""
                                                    style={{ width: '30px' }}
                                                />
                                                <span className="fs-1 fw-semibold pl-2">
                                                    {ttchitietCK.name_bank.toUpperCase()}
                                                </span>
                                            </span>

                                            <div className="d-flex align-items-center justify-content-between px-4 py-3">
                                                <span>Tên: </span>
                                                <span>{ttchitietCK.name_u_bank.toUpperCase()}</span>
                                                {/* <span style={{ color: 'transparent' }}>1111111111111</span> */}
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between pl-4 py-3">
                                                <span>Tài khoản: </span>
                                                <span>{ttchitietCK.stk_bank}</span>
                                                <CopyToClipboard
                                                    text={ttchitietCK.stk_bank}
                                                    onCopy={() => {
                                                        setContentCopy(ttchitietCK.stk_bank);
                                                        setCopied(true);
                                                        setCopiedNoiDung(false);
                                                    }}
                                                >
                                                    <Button
                                                        color="success"
                                                        className="mx-2"
                                                        style={{ fontSize: '16px' }}
                                                    >
                                                        Sao chép
                                                    </Button>
                                                </CopyToClipboard>
                                            </div>

                                            {copied && (
                                                <span style={{ color: 'green', paddingLeft: '40%' }}>Đã sao chép!</span>
                                            )}
                                        </div>
                                        <span className="py-3">Thông tin nạp tiền</span>

                                        <div className="border py-4">
                                            <div className="d-flex align-items-center justify-content-between pl-4 py-2">
                                                <span>Số tiền nạp: </span>
                                                <Input
                                                    className="w-75 mr-4"
                                                    placeholder="Giới hạn nạp từ 20.000Đ đến 3.000.000.000Đ"
                                                    style={{ height: '35px', fontSize: '14px' }}
                                                    onChange={(e) => setMoney(e.target.value)}
                                                ></Input>
                                            </div>

                                            <span
                                                className="ml-4"
                                                style={money ? { display: 'block' } : { display: 'none' }}
                                            >
                                                {VND.format(money)}
                                            </span>

                                            <div className="d-flex align-items-center justify-content-between pl-4 py-2">
                                                <span>Nội dung: </span>
                                                <Input
                                                    className="w-25 mr-4"
                                                    placeholder="Vui lòng nhập tên đăng nhập của bạn!"
                                                    style={{ height: '35px', fontSize: '14px' }}
                                                    defaultValue={noidung}
                                                    disabled
                                                    // onChange={(e) => setNoiDung(e.target.value)}
                                                ></Input>
                                                <CopyToClipboard
                                                    text={noidung}
                                                    onCopy={() => {
                                                        setContentCopy(noidung);
                                                        setCopiedNoiDung(true);
                                                        setCopied(false);
                                                    }}
                                                >
                                                    <Button
                                                        color="success"
                                                        className="mx-2"
                                                        style={{ fontSize: '12px' }}
                                                    >
                                                        Sao chép
                                                    </Button>
                                                </CopyToClipboard>

                                                {copiedNoiDung && (
                                                    <span style={{ color: 'green', paddingLeft: '5px' }}>
                                                        Đã sao chép!
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex flex-column border ml-4 px-2 py-2 align-items-center justify-content-center text-center"
                                        style={{ width: '230px', height: '300px' }}
                                    >
                                        <span className="py-4">Quét mã thanh toán</span>
                                        <img
                                            src={`${baseUrl + ttchitietCK.qr_code}`}
                                            alt=""
                                            style={{ width: '140px', height: '140px' }}
                                        />
                                        <span className="py-4">
                                            Quét mã thanh toán QR, nếu có bất kỳ thắc mắc nào vui lòng liên hệ CSKH để
                                            được tư vấn và hỗ trợ!
                                        </span>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter className="d-flex align-items-center justify-content-center">
                                <Button
                                    color="primary"
                                    onClick={handleRecharge}
                                    style={{ height: '35px', fontSize: '14px' }}
                                >
                                    Xác nhận nạp tiền
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={toggleOne}
                                    style={{ height: '35px', fontSize: '14px' }}
                                    className="ml-4"
                                >
                                    Đóng lại
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )}
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default RechargeBank;
